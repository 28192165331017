.MuiPickersBasePicker-pickerView{
    width: 100% !important;
    max-width: 100% !important;
    /* border-bottom: 2px solid;
    border-left: 2px solid;
    border-right: 2px solid; */
    min-width: 0px !important
}

.MuiPickersCalendarHeader-daysHeader{
    justify-content: space-evenly !important; 
}

.MuiPickersCalendarHeader-dayLabel{
    width: 10% !important;
    max-width: 10% !important;
    font-size: 14px !important
}

.MuiPickersCalendar-week{
    justify-content:space-evenly !important
}

.MuiPickersStaticWrapper-staticWrapperRoot{
    min-width: 0px !important
}

