.HeaderButton{
    height:100px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    color: black;
    display: flex;
    align-items: center;
    text-align: center;
}

.MenuButton{
    height:50px;
    font-size: 15px;
    margin-left: 20px;
    margin-right: 20px;
    color: black;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration: none;
    cursor: pointer
}