.container {
    position: relative;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
  }
  
  .container:hover .overlay {
    display: block;
    background: rgba(0, 0, 0, .3);
  }
  
  .button {
    position: absolute;
    width: 50px;
    right: 0;
    top: 0px;
    text-align: center;
    opacity: 0.2;
    transition: opacity .35s ease;
  }

  .bSmall {
    position: absolute;
    width: 50px;
    right: 0;
    top: 0px;
    text-align: center;
  }
  
  .container:hover .button {
    opacity: 1;
  }