@import url('https://fonts.googleapis.com/css2?family=Karla&family=Rubik&display=swap');


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;

}



.MuiPickersBasePicker-container{
  justify-content: center;
}


.infoIconCal {
  margin-left: 2px; 
  font-size: 8px; 
  border: 1px solid #3366FF; 
  color: #3366FF; 
  border-radius: 50%; 
  width: 12px; 
  height: 12px;
  display:inline-flex;
  justify-content:center;
  align-items:center;
}

.infoHover sup span:hover {
  background-color: #3366FF !important;
  color : #FFFFFF !important;
  width: 16px !important; 
  height: 16px !important;
  font-size: 10px !important;
  cursor: context-menu !important;
}