@import url(https://fonts.googleapis.com/css2?family=Karla&family=Rubik&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;

}



.MuiPickersBasePicker-container{
  justify-content: center;
}


.infoIconCal {
  margin-left: 2px; 
  font-size: 8px; 
  border: 1px solid #3366FF; 
  color: #3366FF; 
  border-radius: 50%; 
  width: 12px; 
  height: 12px;
  display:inline-flex;
  justify-content:center;
  align-items:center;
}

.infoHover sup span:hover {
  background-color: #3366FF !important;
  color : #FFFFFF !important;
  width: 16px !important; 
  height: 16px !important;
  font-size: 10px !important;
  cursor: context-menu !important;
}
.App {
    /* height:100%; */
    font-family: 'Rubik', sans-serif;
    min-height: 100vh;
}

.MuiPickersBasePicker-pickerView{
    width: 100% !important;
    max-width: 100% !important;
    /* border-bottom: 2px solid;
    border-left: 2px solid;
    border-right: 2px solid; */
    min-width: 0px !important
}

.MuiPickersCalendarHeader-daysHeader{
    justify-content: space-evenly !important; 
}

.MuiPickersCalendarHeader-dayLabel{
    width: 10% !important;
    max-width: 10% !important;
    font-size: 14px !important
}

.MuiPickersCalendar-week{
    justify-content:space-evenly !important
}

.MuiPickersStaticWrapper-staticWrapperRoot{
    min-width: 0px !important
}


:root {
    --fc-small-font-size: .85em;
    --fc-page-bg-color: #fff;
    --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
    --fc-neutral-text-color: #808080;
    --fc-border-color: #ddd;
  
    --fc-button-text-color: #1a1f36;
    --fc-button-bg-color: white;
    --fc-button-border-color: grey;
    --fc-button-hover-bg-color: #E8E8E8;
    --fc-button-active-bg-color: #E0E0E0;
    --fc-button-active-border-color: grey;
  
    --fc-event-bg-color: #3788d8;
    --fc-event-border-color: #3788d8;
    --fc-event-text-color: #fff;
    --fc-event-selected-overlay-color: none;
  
    --fc-more-link-bg-color: #d0d0d0;
    --fc-more-link-text-color: inherit;
  
    --fc-event-resizer-thickness: 8px;
    --fc-event-resizer-dot-total-width: 8px;
    --fc-event-resizer-dot-border-width: 1px;
  
    --fc-non-business-color: white;
    --fc-bg-event-color: rgb(143, 223, 130);
    --fc-bg-event-opacity: 0.3;
    --fc-highlight-color: white;
    --fc-today-bg-color: rgba(255, 220, 40, 0.15);
    --fc-now-indicator-color: red;
  }
.container {
    position: relative;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
  }
  
  .container:hover .overlay {
    display: block;
    background: rgba(0, 0, 0, .3);
  }
  
  .button {
    position: absolute;
    width: 50px;
    right: 0;
    top: 0px;
    text-align: center;
    opacity: 0.2;
    transition: opacity .35s ease;
  }

  .bSmall {
    position: absolute;
    width: 50px;
    right: 0;
    top: 0px;
    text-align: center;
  }
  
  .container:hover .button {
    opacity: 1;
  }
.HeaderButton{
    height:100px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    color: black;
    display: flex;
    align-items: center;
    text-align: center;
}

.MenuButton{
    height:50px;
    font-size: 15px;
    margin-left: 20px;
    margin-right: 20px;
    color: black;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration: none;
    cursor: pointer
}
