:root {
    --fc-small-font-size: .85em;
    --fc-page-bg-color: #fff;
    --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
    --fc-neutral-text-color: #808080;
    --fc-border-color: #ddd;
  
    --fc-button-text-color: #1a1f36;
    --fc-button-bg-color: white;
    --fc-button-border-color: grey;
    --fc-button-hover-bg-color: #E8E8E8;
    --fc-button-active-bg-color: #E0E0E0;
    --fc-button-active-border-color: grey;
  
    --fc-event-bg-color: #3788d8;
    --fc-event-border-color: #3788d8;
    --fc-event-text-color: #fff;
    --fc-event-selected-overlay-color: none;
  
    --fc-more-link-bg-color: #d0d0d0;
    --fc-more-link-text-color: inherit;
  
    --fc-event-resizer-thickness: 8px;
    --fc-event-resizer-dot-total-width: 8px;
    --fc-event-resizer-dot-border-width: 1px;
  
    --fc-non-business-color: white;
    --fc-bg-event-color: rgb(143, 223, 130);
    --fc-bg-event-opacity: 0.3;
    --fc-highlight-color: white;
    --fc-today-bg-color: rgba(255, 220, 40, 0.15);
    --fc-now-indicator-color: red;
  }